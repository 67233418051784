import axios from 'axios';

export const URL = process.env.REACT_APP_API_URI;
const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export function login(username, password) {
  return axios.post(`${URL}/api/authenticate`, { username, password }).then((response) => {
    if (response.data.id_token) {
      localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(response.data.id_token));
    }
    return response.data;
  });
}

export function logout() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}

export function register(username, email, password) {
  return this.axios.post(`${URL}/api/singup`, {
    username,
    email,
    password
  });
}

export function getCurrentUser() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}
