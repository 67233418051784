import { filter } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Button,
  Checkbox,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { getAllMobils, deleteMobil } from '../../services/mobil';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import SearchNotFound from '../../components/SearchNotFound';
import MobilMoreMenu from './MobilMoreMenu';
// routes

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'mobilCode', label: 'Nro. Móvil', alignRight: false },
  { id: 'carPatent', label: 'Patente', alignRight: false },
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'model', label: 'Modelo', alignRight: false },
  { id: 'colour', label: 'Color', alignRight: false },
  { id: 'specialChargement', label: 'Cobro Diferenciado', alignRight: false },
  { id: 'enabled', label: 'Habilitado', alignRight: false },
  { id: 'operations', label: '', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  console.log(`query: ${query}`);
  console.log(`array: ${JSON.stringify(array)}`);
  if (query) {
    return filter(
      array,
      // (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      (_user) => parseInt(_user.mobilCode, 10) === parseInt(query, 10)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Mobils() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('mobilCode');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // GET ALL MOBILS
  const [mobils, setMobils] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllMobils().then((items) => {
      if (mounted) {
        console.log(items);
        setMobils(items);
      }
    });
    return () => (mounted = false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = mobils.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    console.log(event.target.value);
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (userId) => {
    console.log(userId);
    setIdUser(userId);
    handleClickOpen();
    // dispatch(deleteUser(userId));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mobils.length) : 0;

  const filteredUsers = applySortFilter(mobils, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // alert dialog
  const [open, setOpen] = useState(false);
  const [idUser, setIdUser] = useState('');
  const [isCloseDialog, setIsCloseDialog] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // delete movil
  const deleteMovil = () => {
    console.log(`eliminando movil: ${idUser}`);
    deleteMobil(idUser).then((items) => {
      console.log(items);
      enqueueSnackbar('Móvil ELIMINADO!', {
        variant: 'success'
      });
      handleClose();
      setIdUser('0');
      setIsCloseDialog(true);
    });
  };

  // useEffect update table
  useEffect(() => {
    console.log('*useEffect 2*');
    getAllMobils().then((items) => {
      console.log(items);
      setMobils(items);
    });
  }, [isCloseDialog]);

  return (
    <Page title="Móviles">
      <Container>
        <HeaderBreadcrumbs
          heading="Lista de Móviles"
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/createmobil"
              state={{ isEdit: false }}
              startIcon={<AddIcon />}
            >
              Nuevo
            </Button>
          }
        />

        <Stack mb={7}>
          <Card sx={{ py: 4, px: 4 }}>
            <UserListToolbar
              nameSearchPlaceHolder="Buscar Móvil"
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={mobils.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          name,
                          mobilCode,
                          carPatent,
                          colour,
                          model,
                          specialChargement,
                          enabled
                        } = row;

                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>
                            <TableCell align="left">{mobilCode}</TableCell>
                            <TableCell align="left">{carPatent}</TableCell>
                            <TableCell align="left">{name}</TableCell>
                            <TableCell align="left">{model}</TableCell>
                            <TableCell align="left">{colour}</TableCell>
                            <TableCell align="left">
                              <Stack alignItems="center" sx={{ width: 1, textAlign: 'center' }}>
                                {specialChargement ? (
                                  <Checkbox checked disabled />
                                ) : (
                                  <Checkbox disabled />
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack alignItems="center" sx={{ width: 1, textAlign: 'center' }}>
                                {enabled ? <Checkbox checked disabled /> : <Checkbox disabled />}
                              </Stack>
                            </TableCell>
                            <TableCell align="right">
                              <MobilMoreMenu onDelete={() => handleDeleteUser(id)} userName={row} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={mobils.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Eliminar Móvil</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¿Seguro que deséa eliminar el móvil?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={deleteMovil}>
                Eliminar
              </Button>
              <Button color="inherit" onClick={handleClose} autoFocus>
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Container>
    </Page>
  );
}
