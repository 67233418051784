import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { es } from 'date-fns/locale';
import jwtdecode from 'jwt-decode';

// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  Grid,
  TextField,
  Button,
  Autocomplete,
  TablePagination,
  CardHeader
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { v1 as uuidv1 } from 'uuid';
import { sorting } from '../services/utils';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { getAllMobils } from '../services/mobil';
import {
  getAllCashBoxCanceledByName,
  getCashBoxByMobilAndDeadTimeDate,
  updateCashBoxById
} from '../services/cashbox';
import { getOperatorById } from '../services/operator';
import Label from '../components/Label';
import SearchNotFoundTwo from '../components/SearchNotFoundTwo';
import { getCurrentUser } from '../services/auth.service';
import { getUserByLoginName } from '../services/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'costo', label: 'Costo', alignRight: false },
  { id: 'estado', label: 'Estado', alignRight: false },
  { id: 'fecha_pago', label: 'Fecha de Pago', alignRight: false },
  { id: 'mobil', label: 'Móvil', alignRight: false },
  { id: 'anumado_por', label: 'Anulado por', alignRight: false },
  { id: 'fecha_emision', label: 'Fecha de Emisión', alignRight: false },
  { id: 'voucherNumber', label: 'Comprobante', alignRight: false }
];

const ContentStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(5),
  color: theme.palette.common.black,
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.grey[100]} 0%,
    ${theme.palette.grey[400]} 100%)`
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function CancelTicket() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [enablePaid, setEnablePaid] = useState(true);
  const [cashbox, setCashBox] = useState();
  const [autoCloseDatePicker, setAutoCloseDatePicker] = useState(true);

  // Formik
  const LoginSchema = Yup.object().shape({
    deadTimeDate: Yup.date().nullable().required('End date is required'),
    mobil: Yup.object().nullable().required('Movil es Requerido')
  });

  const formik = useFormik({
    initialValues: {
      voucherNumber: uuidv1(),
      currentData: new Date(),
      deadTimeDate: new Date(),
      mobil: '',
      operator: '',
      amount: '',
      cancelled: null,
      cancelledBy: null,
      user: null
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      console.log(data);
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    handleBlur
  } = formik;

  // GET ALL MOBILS
  const [mobils, setMobils] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllMobils().then((items) => {
      if (mounted) {
        const mobils = items.sort((a, b) => a.mobilCode - b.mobilCode);
        setMobils(mobils);
      }
    });
    return () => (mounted = false);
  }, []);

  // GET ALL CANCEL CASHBOXES
  const [cashboxes, setCashBoxes] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllCashBoxCanceledByName().then((items) => {
      if (mounted) {
        setCashBoxes(sorting(items));
      }
    });
    return () => (mounted = false);
  }, []);

  // GET OPERATOR BY ID
  const [operator, setOperator] = useState('');
  useEffect(() => {
    let mounted = true;
    getOperatorById(1).then((operador) => {
      if (mounted) {
        setOperator(operador);
        setFieldValue('operator', operador);
      }
    });
    return () => (mounted = false);
  }, []);

  // GET USER BY LOGIN NAME
  useEffect(() => {
    const user = getCurrentUser();
    const decoded = jwtdecode(user);
    let mounted = true;
    getUserByLoginName(decoded.sub).then((items) => {
      if (mounted) {
        console.log(items);
        setFieldValue('user', items);
      }
    });
    return () => (mounted = false);
  }, []);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  // Set locale Moment;
  moment.locale('es');

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // CANCELAR TICKET
  const cancelCashBox = () => {
    console.log('anulando...');
    console.log(cashbox);
    if (cashbox.state.name === 'ANULADO') {
      enqueueSnackbar(`Error: el ticket ya se encuentra anulado por ${cashbox.cancelledBy}`, {
        variant: 'error'
      });
    } else {
      const state = {
        id: 2,
        name: 'ANULADO'
      };
      cashbox.cancelled = true;
      cashbox.state = state;
      cashbox.cancelledBy = values.user.login;
      const data = cashbox;
      updateCashBoxById(data)
        .then((items) => {
          console.log(items);
          if (items.id) {
            console.log(items);
            enqueueSnackbar('Ticket Anulado', {
              variant: 'success'
            });
            getAllCashBoxCanceledByName(data).then((items) => {
              setCashBoxes(sorting(items));
              console.log(items);
            });
          } else {
            console.log(items);
            enqueueSnackbar('Error', {
              variant: 'error'
            });
          }
        })
        .catch((error) => {
          const { response } = error;
          console.log(response);
          console.log(response.data);
          if (response.data.status === 500) {
            enqueueSnackbar(response.data.detail, {
              variant: 'error'
            });
          }
        });
    }
  };

  const updateMobil = (value) => {
    setFieldValue('mobil', value || '');
    if (value != null) {
      getTickets(value, values.deadTimeDate);
    }
  };

  const updateDate = (value) => {
    setFieldValue('deadTimeDate', value);
    if (value != null) {
      getTickets(values.mobil, value);
    }
  };

  const getTickets = (mobil, date) => {
    setEnablePaid(false);
    console.log(values);
    values.mobil = mobil;
    values.deadTimeDate = date;

    values.deadTimeDate = moment(values.deadTimeDate).locale('es').format('YYYY-MM-DD');
    getCashBoxByMobilAndDeadTimeDate(values)
      .then((cashbox) => {
        if (cashbox.id) {
          console.log(cashbox);
          enqueueSnackbar('Ticket encontrado', {
            variant: 'success'
          });
          setCashBox(cashbox);
        }
      })
      .catch((err) => {
        const { response } = err;
        console.log(response);
        if (response.data.status === 404) {
          enqueueSnackbar('Ticket no encontrado', {
            variant: 'error'
          });
          setEnablePaid(true);
          setCashBox(null);
        }
      });
  };

  return (
    <Page title="Anular Ticket">
      <Container>
        <HeaderBreadcrumbs heading="Anular Ticket" />
        <Stack mb={7}>
          <Grid container spacing={3}>
            <Grid item md={7} xs={12}>
              <Card sx={{ py: 5, px: 5 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                        <Autocomplete
                          id="contact-autocomplete"
                          fullWidth
                          options={mobils}
                          getOptionLabel={(option) => option.mobilCode.toString()}
                          onChange={(e, value) => updateMobil(value)}
                          onOpen={handleBlur}
                          includeInputInList
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nro. Móvil"
                              name="mobil"
                              error={Boolean(touched.mobil && errors.mobil)}
                              helperText={touched.mobil && errors.mobil}
                            />
                          )}
                        />
                      </Stack>
                      <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            closeOnSelect={autoCloseDatePicker}
                            label="Fecha de Pago"
                            {...getFieldProps('deadTimeDate')}
                            onChange={(value) => updateDate(value)}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                error={Boolean(touched.deadTimeDate && errors.deadTimeDate)}
                              />
                            )}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
            <Grid item md={5} xs={12}>
              <ContentStyle>
                <Typography variant="subtitle2" gutterBottom>
                  Costo Total:
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="h3">{cashbox?.amount} u</Typography>

                  <RowStyle>
                    <Typography variant="subtitle1">Operador</Typography>
                    <Typography variant="subtitle1">{cashbox?.user.login}</Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="body2">Comprobante</Typography>
                    <Typography variant="body2">{cashbox?.voucherNumber}</Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="body2">Nro. Móvil</Typography>
                    <Typography variant="body2">{cashbox?.mobil.mobilCode}</Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="body2">Fecha Emisión</Typography>
                    <Typography variant="body2">
                      {cashbox ? moment(cashbox?.currentData).locale('es').format('llll') : null}
                    </Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="body2">Fecha Pago</Typography>
                    <Typography variant="body2">
                      {cashbox ? moment(cashbox?.deadTimeDate).locale('es').format('llll') : null}
                    </Typography>
                  </RowStyle>

                  <Stack direction="row" spacing={1.5}>
                    <Button
                      disabled={enablePaid}
                      fullWidth
                      variant="contained"
                      color="error"
                      onClick={() => cancelCashBox()}
                    >
                      Anular
                    </Button>
                  </Stack>
                </Stack>
              </ContentStyle>
            </Grid>
          </Grid>
        </Stack>

        <Stack mb={4}>
          <Card sx={{ py: 4, px: 4 }}>
            <CardHeader sx={{ px: 0 }} title="Tickets anulados" />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((row) => {
                        const { label, id } = row;
                        return <TableCell key={id}>{label}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cashboxes
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          amount,
                          currentData,
                          deadTimeDate,
                          mobil,
                          state,
                          voucherNumber
                        } = row;
                        return (
                          <TableRow key={id}>
                            <TableCell align="left">{amount}</TableCell>
                            <TableCell align="right">
                              <Stack alignItems="center" sx={{ width: 1, textAlign: 'center' }}>
                                {state.name === 'ANULADO' ? (
                                  <Label variant="filled" color="error">
                                    {state.name}
                                  </Label>
                                ) : null}
                                {state.name === 'NO_TRABAJO' ? (
                                  <Label variant="filled" color="default">
                                    {state.name}
                                  </Label>
                                ) : null}
                                {state.name === 'PAGADO' ? (
                                  <Label variant="filled" color="primarygreen">
                                    {state.name}
                                  </Label>
                                ) : null}
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {moment(row.deadTimeDate).format('L')}
                            </TableCell>
                            <TableCell align="left">{mobil.mobilCode}</TableCell>
                            <TableCell align="left">{row.cancelledBy}</TableCell>
                            <TableCell align="left">
                              {moment(row.currentData).format('llll')}
                            </TableCell>
                            <TableCell align="left">{voucherNumber}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {cashboxes.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFoundTwo />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={cashboxes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
