import * as Yup from 'yup';
import { useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
// material
import {
  Card,
  Stack,
  Container,
  Grid,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from '@mui/material';
// components
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import AnalyticsWidgetSummary from '../components/AnalyticsWidgetSummary';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { getAllSettings, updateSettingsById } from '../services/settings';
import AnalyticsWidgetSummaryEnabledRangeTime from '../components/AnalyticsWidgetSummaryEnabledRangeTime';

// ----------------------------------------------------------------------

export default function Settings() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Formik
  const LoginSchema = Yup.object().shape({
    importOne: Yup.date().nullable().required('importOne is required'),
    importTwo: Yup.date().nullable().required('importTwo is required')
  });

  const formik = useFormik({
    initialValues: {
      id: null,
      postDuration: null,
      importOne: 0,
      importTwo: 0,
      importThree: 0,
      enabledrangetime: false
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { resetForm, setSubmitting }) => {
      console.log(data);
      updateSettingsById(data).then((items) => {
        if (items.id) {
          console.log(items);
          enqueueSnackbar('Configuración actualizada con éxito', {
            variant: 'success'
          });
          setSubmitting(false);
        } else {
          console.log(items);
          enqueueSnackbar('Error: No se pudo actualizar la Configuración.', {
            variant: 'error'
          });
        }
      });
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    handleBlur
  } = formik;

  // GET SETTING
  useEffect(() => {
    let mounted = true;
    getAllSettings().then((items) => {
      if (mounted) {
        console.log(items[0]);
        setFieldValue('id', items[0].id);
        setFieldValue('postDuration', items[0].postDuration);
        setFieldValue('importThree', items[0].importThree);
        setFieldValue('importTwo', items[0].importTwo);
        setFieldValue('importOne', items[0].importOne);
        setFieldValue('enabledrangetime', items[0].enabledrangetime);
      }
    });
    return () => (mounted = false);
  }, []);

  return (
    <Page title="Configuración">
      <Container>
        <HeaderBreadcrumbs heading="Configuración" />
        <Stack mb={7}>
          <Grid
            mb={7}
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Tarifa #1"
                total={getFieldProps('importOne').value}
                color="info"
                icon="ant-design:dollar-circle-filled"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Tarifa #2"
                total={getFieldProps('importTwo').value}
                color="primarypurple"
                icon="ant-design:dollar-circle-filled"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummaryEnabledRangeTime
                title="Control Horario"
                enabled={getFieldProps('enabledrangetime').value}
                color="primarygreen"
                icon="ant-design:clock-circle-twotone"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={5}>
              <Card sx={{ py: 5, px: 5 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <TextField
                        type="number"
                        fullWidth
                        label="Tarifa #1"
                        {...getFieldProps('importOne')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          type: 'number'
                        }}
                        error={Boolean(touched.importOne && errors.importOne)}
                        helperText={touched.importOne && errors.importOne}
                      />
                      <TextField
                        type="number"
                        fullWidth
                        label="Tarifa #2"
                        {...getFieldProps('importTwo')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          type: 'number'
                        }}
                        error={Boolean(touched.importTwo && errors.importTwo)}
                        helperText={touched.importTwo && errors.importTwo}
                      />
                      <Stack spacing={0}>
                        <FormControlLabel
                          label="Habilitar Control Horario"
                          control={
                            <Checkbox
                              {...getFieldProps('enabledrangetime')}
                              checked={values.enabledrangetime}
                            />
                          }
                        />
                      </Stack>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        size="large"
                      >
                        Actualizar Configuración
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
}
