import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import slashFill from '@iconify/icons-eva/slash-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function NotAuthorized() {
  return (
    <RootStyle title="404 Page Not Found | Minimal-UI">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                NO AUTORIZADO
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Usted no tiene permisos para ingresar a esta sección dentro del sistema.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="span"
                sx={{
                  mx: 'auto',
                  width: 350,
                  height: 350,
                  display: 'flex',
                  borderRadius: 1.5,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon icon={slashFill} width={200} height={200} />
              </Box>
            </motion.div>

            <Button to="/dashboard/cobro" size="large" variant="contained" component={RouterLink}>
              VOLVER
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
