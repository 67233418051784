import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// components
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}));

// ----------------------------------------------------------------------

AnalyticsWidgetSummaryEnabledRangeTime.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error']),
  icon: PropTypes.string,
  title: PropTypes.string,
  enabled: PropTypes.number
};

export default function AnalyticsWidgetSummaryEnabledRangeTime({ title, enabled, icon, color }) {
  const colorDynamic = enabled ? color : 'error';

  return (
    <RootStyle
      sx={{
        color: (theme) => theme.palette[colorDynamic].darker,
        bgcolor: (theme) => theme.palette[colorDynamic].lighter
      }}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[colorDynamic].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[colorDynamic].dark, 0)} 0%, ${alpha(
              theme.palette[colorDynamic].dark,
              0.24
            )} 100%)`
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle>
      <Typography sx={{ my: 2 }} variant="h5">
        {enabled ? 'HABILITADO' : 'DESHABILITADO'}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </RootStyle>
  );
}
