import instance from './api';

export const URL = process.env.REACT_APP_API_URI;

export function getAllSettings() {
  return instance.get(`${URL}/api/settings`);
}

export function getSettingsById(id) {
  return instance.get(`${URL}/api/settings/${id}`);
}

export function updateSettingsById(setting) {
  return instance.put(`${URL}/api/settings/${setting.id}`, setting);
}

export function getPublicAllSettings() {
  return instance.get(`${URL}/api/getpublicsettings`);
}
