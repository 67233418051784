import { useEffect } from 'react';
import { paramCase } from 'change-case';
import { useParams, useLocation, Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { Container, Button } from '@mui/material';
import { MobilForm } from '../../components/forms';
// redux
// import { useDispatch, useSelector } from '../../redux/store';
// import { getUserList } from '../../redux/slices/user';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
// import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import UserForm from './UserForm';
// import UserNewForm from '../../components/_dashboard/user/UserNewForm';

// ----------------------------------------------------------------------

export default function UserCreate() {
  // const { themeStretch } = useSettings();
  // const dispatch = useDispatch();
  const { pathname } = useLocation();
  const location = useLocation();
  const { currentUser, isEdit } = location.state;
  // const { isEdit } = location.isEdit;
  const { name } = useParams();
  // const { userList } = useSelector((state) => state.user);
  // const isEdit = pathname.includes('edit');
  // const currentUser = userList.find((user) => paramCase(user.name) === name);

  // useEffect(() => {
  //   dispatch(getUserList());
  // }, [dispatch]);

  return (
    <Page title="Usuarios: Nuevo">
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
      <Container>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Nuevo Usuario' : 'Editar Usuario'}
          // links={[
          //   { name: 'Dashboard', href: PATH_DASHBOARD.root },
          //   { name: 'User', href: PATH_DASHBOARD.user.root },
          //   { name: !isEdit ? 'New user' : name }
          // ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/users"
              state={{ isEdit: false }}
              startIcon={<ArrowBackIosIcon />}
            >
              Regresar
            </Button>
          }
        />

        <UserForm isEdit={isEdit} currentUser={currentUser} />
      </Container>
    </Page>
  );
}
