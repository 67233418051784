// ----------------------------------------------------------------------

const account = {
  displayName: 'Fernando Gonzalez',
  email: 'fagonzalez.nt@gmail.com',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  role: ''
};

export default account;
