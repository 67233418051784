import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFoundTwo.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFoundTwo({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Sin resultados por el momento ...
      </Typography>
      {/* <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
      </Typography> */}
    </Paper>
  );
}
