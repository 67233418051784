import instance from './api';

export const URL = process.env.REACT_APP_API_URI;

export function getAllMobils() {
  return instance.get(`${URL}/api/mobils`);
}

export function getMobilById(id) {
  return instance.get(`${URL}/api/mobils/${id}`);
}

export function createMobil(mobil) {
  return instance.post(`${URL}/api/mobils`, mobil);
}

export function deleteMobil(id) {
  return instance.delete(`${URL}/api/mobils/${id}`);
}

export function updateMobil(mobil) {
  return instance.put(`${URL}/api/mobils/${mobil.id}`, mobil);
}
