import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect, Fragment, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import '../../theme/mystyles.css';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { addLocale } from 'primereact/api';
import jwtdecode from 'jwt-decode';
// primereact
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Button as ButtonPr } from 'primereact/button';
import { Dialog as DialogPr } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
// material
import {
  Card,
  Container,
  Button,
  Stack,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogContent,
  DialogContentText
} from '@mui/material';
// components
import { LoadingButton } from '@mui/lab';
import { sorting, addLocaleConfig } from '../../services/utils';
import Page from '../../components/Page';
import { getAllMobils } from '../../services/mobil';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  deleteCashBoxById,
  deleteCashBoxes,
  getCashBoxByMobilAndBetweenDeadTimeDate,
  getCashBoxByVaucherId,
  updateCashBoxById,
  getCashBoxesByMobilAndDeadTimeDate,
  createCashBoxByRangeDays
} from '../../services/cashbox';
import { getCurrentUser } from '../../services/auth.service';
import { getUserByLoginName } from '../../services/user';
import { getAllStates } from '../../services/state';
import { ComponentToPrint } from '../../components/ComponentToPrint';

const OPTIONS = ['Calendario', 'Comprobante'];

export default function CashBoxes() {
  const [cashboxes, setCashBoxes] = useState([]);
  const [valueSimple, setValueSimple] = useState(new Date());
  const [valueDetallado, setValueDetallado] = useState([null, null]);
  const [trueValue, setTrueValue] = useState(true);
  const [rowClick, setRowClick] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [cashBox, setCashBox] = useState(null);
  const toast = useRef(null);
  const [mobils, setMobils] = useState([]);
  const [open, setOpen] = useState(false);
  const [disabledPaid, setDisabledPaid] = useState(true);
  const [dates, setDates] = useState([new Date()]);
  const [viewDate, setViewDate] = useState();
  const [trueVlue, setTrueVlue] = useState(true);
  const [isSubmittingCharge, setSubmittingCharge] = useState(false);
  const [states, setStates] = useState([]);

  // SET MOMENT "es"
  moment.locale('es');
  addLocale('es', addLocaleConfig);

  // Formik
  const LoginSchema = Yup.object().shape({
    mobil: Yup.object().nullable().required('Movil es Requerido')
  });

  const formik = useFormik({
    initialValues: {
      dateStart: null,
      dateEnd: null,
      mobil: '',
      tipopago: 'Calendario',
      amount: null
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      const [start, end] = valueDetallado;
      console.log(valueSimple);
      let empty = true;

      if (values.tipopago === 'Simple') {
        empty = valueSimple === null;
      } else {
        empty = start === null || end === null;
      }

      if (empty) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Debe seleccionar un rago de fecha primero',
          life: 3000
        });
        setSubmitting(false);
      } else {
        if (values.tipopago === 'Simple') {
          data.dateStart = moment(valueSimple).startOf('month');
          data.dateEnd = moment(valueSimple).endOf('month');
          console.log(data.dateStart);
          console.log(data.dateEnd);
        } else {
          data.dateStart = start;
          data.dateEnd = end;
        }
        console.log(data);

        getCashBoxByMobilAndBetweenDeadTimeDate(data).then((cashboxes) => {
          if (cashboxes.length > 0) {
            console.log(cashboxes);
            toast.current.show({
              severity: 'success',
              summary: 'OK!',
              detail: 'Tickets encontrados',
              life: 3000
            });
            setCashBoxes(sorting(cashboxes));
          } else {
            console.log(cashboxes);
            setCashBoxes([]);
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: 'No existen tickets',
              life: 3000
            });
          }
          setSubmitting(false);
        });
      }
    }
  });

  const { errors, touched, values, handleSubmit, setFieldValue, getFieldProps, handleBlur } =
    formik;

  // GET ALL MOBILS
  useEffect(() => {
    let mounted = true;
    getAllMobils().then((items) => {
      if (mounted) {
        const mobils = items.sort((a, b) => a.mobilCode - b.mobilCode);
        setMobils(mobils);
      }
    });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let mounted = true;
    getAllStates().then((items) => {
      if (mounted) {
        setStates(items);
      }
    });
    const user = getCurrentUser();
    const userDecoded = jwtdecode(user);
    getUserByLoginName(userDecoded.sub).then((items) => {
      if (mounted) {
        setFieldValue('user', items);
      }
    });
    return () => (mounted = false);
  }, []);

  // delete Cashbox
  const deleteCashbox = (cashboxId, _products) => {
    console.log(`eliminando movil: ${cashboxId}`);
    deleteCashBoxById(cashboxId)
      .then((items) => {
        setCashBox(null);
        setCashBoxes(_products);
        toast.current.show({
          severity: 'success',
          summary: 'OK!',
          detail: 'Ticket eliminado',
          life: 3000
        });
      })
      .catch((error) => {
        const { response } = error;
        console.log(response);
        if (response.data.detail?.includes('delete from turn where id')) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'No se puede eliminar ticket asociado a un turno cerrado.',
            life: 3000
          });
        }
      });
  };

  const deleteCashboxes = (cashBoxes, _products) => {
    console.log(`eliminando cashboxes..`);
    deleteCashBoxes(cashBoxes)
      .then((items) => {
        console.log(items);
        setCashBoxes(_products);
        setSelectedProducts(null);
        toast.current.show({
          severity: 'success',
          summary: 'OK!',
          detail: 'Ticket eliminado',
          life: 3000
        });
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        console.log(response);
        if (response.data.error?.includes('delete from turn where id')) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'No se puede eliminar ticket asociado a un turno cerrado.',
            life: 3000
          });
        }
      });
  };

  // RANGE PICKER
  console.log(cashboxes);
  const paied = cashboxes
    .filter((cash) => {
      let paid;
      if (cash.state.name === 'PAGADO') {
        paid = cash;
      }
      return paid;
    })
    .map((cash) => cash.deadTimeDate);
  console.log(paied);

  const anulated = cashboxes
    .filter((cash) => {
      let anulated;
      if (cash.state.name === 'ANULADO') {
        anulated = cash;
      }
      return anulated;
    })
    .map((cash) => cash.deadTimeDate);
  console.log(anulated);

  const notworked = cashboxes
    .filter((cash) => {
      let notworked;
      if (cash.state.name === 'NO_TRABAJO') {
        notworked = cash;
      }
      return notworked;
    })
    .map((cash) => cash.deadTimeDate);
  console.log(notworked);

  const onRowEditComplete = (e) => {
    console.log('editando Cashbox');
    const _cashboxes = [...cashboxes];
    const { newData, index } = e;
    _cashboxes[index] = newData;

    console.log(_cashboxes);

    setCashBoxes(_cashboxes);

    console.log(e);

    updateCashBoxById(newData).then((result) => {
      console.log(result);
      toast.current.show({
        severity: 'success',
        summary: 'OK!',
        detail: 'Ticket Actualizado!',
        life: 3000
      });
    });
  };

  const statusBodyTemplate = (cashbox) => {
    console.log(cashbox);
    return <Tag value={cashbox.state.name} className={getStyle(cashbox)} />;
  };

  const getStyle = (cashbox) => {
    switch (cashbox.state.name) {
      case 'PAGADO':
        return 'pagado';
      case 'NO_TRABAJO':
        return 'no_trabajo';
      case 'ANULADO':
        return 'anulado';
      default:
        return null;
    }
  };

  const getSeverity = (option) => {
    console.log(option);
    switch (option.name) {
      case 'PAGADO':
        return 'pagado';
      case 'NO_TRABAJO':
        return 'no_trabajo';
      case 'ANULADO':
        return 'anulado';
      default:
        return null;
    }
  };

  const statusEditor = (options) => {
    console.log(options);
    return (
      <Dropdown
        value={options.value}
        options={states}
        onChange={(e) => {
          console.log(e);
          options.editorCallback(e.target.value);
        }}
        optionLabel="name"
        placeholder="Select a Status"
        itemTemplate={(option) => {
          console.log(option);
          return <Tag value={option.name} className={getSeverity(option)} />;
        }}
      />
    );
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const leftToolbarTemplate = () => {
    console.log('leftToolbarTemplate');
    return (
      <div className="flex flex-wrap gap-2">
        <ButtonPr
          label="Eliminar"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
        />
      </div>
    );
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const deleteSelectedProducts = () => {
    console.log(selectedProducts);

    const _products = cashboxes.filter((val) => !selectedProducts.includes(val));

    console.log(_products);

    setDeleteProductsDialog(false);

    deleteCashboxes(selectedProducts, _products);
  };

  const deleteProduct = () => {
    console.log(cashBox);

    const _products = cashboxes.filter((val) => val.id !== cashBox.id);

    console.log(_products);

    setDeleteProductDialog(false);

    deleteCashbox(cashBox.id, _products);
  };

  const deleteProductDialogFooter = (
    <Fragment key="key">
      <ButtonPr label="No" icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
      <ButtonPr label="Yes" icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </Fragment>
  );
  const deleteProductsDialogFooter = (
    <Fragment key="key2">
      <ButtonPr label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <ButtonPr label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </Fragment>
  );

  const confirmDeleteProduct = (cashBox) => {
    setCashBox(cashBox);
    setDeleteProductDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    console.log(rowData);
    return (
      <Fragment key="key3">
        <div className="card flex flex-wrap justify-content-center gap-3">
          <ButtonPr
            icon="pi pi-trash"
            severity="danger"
            onClick={() => confirmDeleteProduct(rowData)}
            className="mr-2"
            style={{ marginInline: '15px' }}
          />
          <ButtonPr
            className="mr-2"
            icon="pi pi-print"
            severity="secondary"
            onClick={() => handleClickOpen(rowData)}
          />
        </div>
      </Fragment>
    );
  };

  const handleClickOpen = (rowData) => {
    setCashBox(rowData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  const searchByVoucherId = (event) => {
    const { value } = event.target;
    if (value) {
      getCashBoxByVaucherId(value)
        .then((items) => {
          console.log(items);
          if (items) {
            const cashBoxes = [];
            cashBoxes.push(items);
            setCashBoxes(cashBoxes);
          } else {
            setCashBoxes([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCashBoxes([]);
    }
  };

  // CALENDAR FUNCTION

  // GET TICKETS BY MOBIL AND DEADTIME
  const getTicketsByMobilAndDeadTime = (data) => {
    const cashbox = data;
    getCashBoxesByMobilAndDeadTimeDate(cashbox).then((items) => {
      setCashBoxes(items);
      console.log(items);
    });
  };

  // HANDLE UPDATE MOBIL
  const handleUpdateMobil = (value) => {
    setFieldValue('mobil', value || '');
    if (value != null && values.deadTimeDate) {
      console.log('selected movil');
      const cashbox = {
        mobil: value,
        deadTimeDate: values.deadTimeDate
      };
      getTicketsByMobilAndDeadTime(cashbox);
    } else {
      setDisabledPaid(true);
    }

    if (dates && dates.length !== 0 && value !== null) {
      console.log('SIN DATES!!');
      setDisabledPaid(false);
    }
  };

  // HANDLE UPDATE DATE BY CLICK
  const handleOnChangeCalendar = (event) => {
    console.log(event);
    setDates(event.value);

    if (event.value?.length > 0) {
      setDisabledPaid(false);
    } else {
      setDisabledPaid(true);
    }
  };

  // HANDLE UPDATE DEADTIMEDATE BY RANGE
  const handleOnMonthChangeCalendar = (event) => {
    handleOnMonthChange(event.value);
    console.log(event);
    setViewDate(event.value);
  };

  // HANDLE ON MONTH CHANGE
  const handleOnMonthChange = (event) => {
    if (values.mobil) {
      console.log(event);
      const date = moment(event).locale('es').format('YYYY-MM-DD');
      const cashbox = {
        mobil: values.mobil,
        deadTimeDate: date
      };
      setFieldValue('deadTimeDate', date || null);
      console.log(cashbox);
      getTicketsByMobilAndDeadTime(cashbox);
    }
  };

  const dateTemplate = (date) => {
    const day = date.day.toString();
    const month = (date.month + 1).toString();

    const isEncontrado = dates?.some((date1) => {
      const onlyDay = moment(date1, 'YYYY-MM-DD').format('D');
      const onlyMonth = moment(date1, 'YYYY-MM-DD').format('M');
      return onlyDay === day && onlyMonth === month;
    });

    const checkStatus = (status, statusDates, className) => {
      if (statusDates.length > 0) {
        const statusDay = statusDates.find(
          (dayStatus) => moment(dayStatus, 'YYYY-MM-DD').format('D') === day
        );
        if (statusDay && !isEncontrado) {
          return (
            <span
              className={className}
              style={{
                borderRadius: '50%',
                width: '2.5rem',
                height: '2.5rem',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                color: 'white'
              }}
            >
              {date.day}
            </span>
          );
        }
      }
      return null;
    };

    const paidElement = checkStatus('pagado', paied, 'pagado');
    if (paidElement) return paidElement;

    const anulatedElement = checkStatus('anulado', anulated, 'anulado');
    if (anulatedElement) return anulatedElement;

    const notWorkedElement = checkStatus('no trabajado', notworked, 'no_trabajo');
    if (notWorkedElement) return notWorkedElement;

    if (isEncontrado) {
      return (
        <span
          className="selected"
          style={{
            borderRadius: '50%',
            width: '2.5rem',
            height: '2.5rem',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: 'white'
          }}
        >
          {date.day}
        </span>
      );
    }

    return date.day;
  };

  // MAIN CHARGE
  const cashBoxExecute = () => {
    console.log('iniciando cobro..');
    setDisabledPaid(true);
    setSubmittingCharge(true);

    const data = formik.values;
    const state = {
      id: 3,
      name: 'NO_TRABAJO'
    };
    data.state = state;
    data.currentData = moment.utc(data.currentData).format();
    data.deadTimeDate = moment(values.deadTimeDate).locale('es').format('YYYY-MM-DD');
    console.log(data);

    payByManyDay(data);
  };

  const payByManyDay = (data) => {
    const newarr = dates.sort((a, b) => moment(a).diff(b));
    const datesnew = newarr.map((date) => moment(date).locale('es').format('YYYY-MM-DD'));
    console.log(datesnew);
    data.deadTimeDateList = datesnew;
    data.amount = 0;

    createCashBoxByRangeDays(data)
      .then((response) => {
        const reponses = response.data;
        console.log(reponses);

        if (reponses) {
          const cashboxesok = [];
          const responsesOk = reponses?.reduce((accumulator, response) => {
            if (response.code === 'OK') {
              accumulator += accumulator + 1;
              console.log(response.cashbox);
              cashboxesok.push(response.cashbox);
              toast.current.show({
                severity: 'success',
                summary: 'OK!',
                detail: response.message,
                life: 3000
              });
            }

            if (
              response.code === 'NOK' &&
              response.error?.includes('unique_mobilid_deadtimedate')
            ) {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Error: Ya existe este cobro',
                life: 3000
              });
            } else if (response.code === 'NOK') {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: response.message,
                life: 3000
              });
            }
            return accumulator;
          }, 0);

          console.log(cashboxesok);
          setFieldValue('manyVauchers', cashboxesok);
          console.log(responsesOk);

          if (responsesOk > 0) {
            handlePrint();
            getCashBoxesByMobilAndDeadTimeDate(data).then((items) => {
              setCashBoxes(items);
              console.log(items);
            });
          }

          setDisabledPaid(true);
          setDates([]);
          setSubmittingCharge(false);
        }
      })
      .catch((error) => {
        const { response } = error;
        console.log(response);
        console.log(response.data);
        if (
          response.statusText === 'Bad Request' &&
          response.data.detail?.includes('could not be parsed at index 0')
        ) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Error: debe seleccionar fecha',
            life: 3000
          });
        }
        setDisabledPaid(true);
        setDates([]);
        setSubmittingCharge(false);
      });
  };

  return (
    <Page title="Registros">
      <Container>
        <HeaderBreadcrumbs
          heading="Lista de Tickets"
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/createcashbox"
              state={{ isEdit: false }}
              startIcon={<AddIcon />}
            >
              Nuevo
            </Button>
          }
        />

        <Stack mb={7}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Card sx={{ py: 5, px: 5 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3} alignItems="center">
                      <FormControl component="fieldset">
                        <RadioGroup {...getFieldProps('tipopago')} row defaultValue="nn">
                          {OPTIONS.map((tipopago) => (
                            <FormControlLabel
                              key={tipopago}
                              value={tipopago}
                              control={<Radio />}
                              label={tipopago}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      {values.tipopago === 'Comprobante' ? (
                        <>
                          <TextField
                            autoFocus
                            type="number"
                            fullWidth
                            onChange={(event) => searchByVoucherId(event)}
                            error={Boolean(touched.amount && errors.amount)}
                            helperText={touched.amount && errors.amount}
                          />
                        </>
                      ) : (
                        <>
                          <Card sx={{ py: 5, px: 5 }}>
                            <FormikProvider value={formik}>
                              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3}>
                                  <Autocomplete
                                    id="contact-autocomplete"
                                    fullWidth
                                    options={mobils}
                                    getOptionLabel={(option) => option.mobilCode.toString()}
                                    onChange={(e, value) => handleUpdateMobil(value)}
                                    onOpen={handleBlur}
                                    blurOnSelect
                                    includeInputInList
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Nro. Móvil"
                                        name="mobil"
                                        error={Boolean(touched.mobil && errors.mobil)}
                                        helperText={touched.mobil && errors.mobil}
                                      />
                                    )}
                                  />
                                  <Calendar
                                    value={dates}
                                    onChange={(e) => handleOnChangeCalendar(e)}
                                    viewDate={viewDate}
                                    onViewDateChange={(e) => handleOnMonthChangeCalendar(e)}
                                    locale="es"
                                    selectionMode="multiple"
                                    inline
                                    dateTemplate={dateTemplate}
                                    showOtherMonths={false}
                                    showButtonBar={trueVlue}
                                  />
                                </Stack>
                              </Form>
                            </FormikProvider>
                            <Stack direction="row" spacing={1.5} style={{ marginTop: '20px' }}>
                              <LoadingButton
                                fullWidth
                                variant="contained"
                                color="mygrey"
                                loading={isSubmittingCharge}
                                disabled={disabledPaid}
                                onClick={() => cashBoxExecute()}
                              >
                                Marcar como: NO TRABAJADO
                              </LoadingButton>
                            </Stack>
                          </Card>
                        </>
                      )}
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
          </Grid>
        </Stack>

        <Stack mb={7}>
          <Toast ref={toast} />

          <div className="card">
            <Toolbar className="mb-4" start={leftToolbarTemplate} />
            <DataTable
              value={cashboxes}
              showGridlines
              paginator
              rows={50}
              rowsPerPageOptions={[5, 10, 25, 50]}
              tableStyle={{ minWidth: '50rem' }}
              size="small"
              editMode="row"
              dataKey="id"
              onRowEditComplete={onRowEditComplete}
              selectionMode={rowClick ? null : 'checkbox'}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
              <Column field="id" header="ID" />
              <Column field="deadTimeDate" header="Fecha" />
              <Column field="amount" header="Total" />
              <Column field="voucherNumber" header="Comprobante" />
              <Column field="mobil.mobilCode" header="Móvil" />
              <Column field="user.login" header="Operador" />
              <Column
                field="state"
                header="Estado"
                editor={(options) => statusEditor(options)}
                body={statusBodyTemplate}
                bodyStyle={{ textAlign: 'center' }}
              />
              <Column
                header="Editar"
                rowEditor={trueValue}
                headerStyle={{ width: '10%', minWidth: '8rem' }}
                bodyStyle={{ textAlign: 'center' }}
              />
              <Column
                header="Operaciones"
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: '12rem' }}
                bodyStyle={{ textAlign: 'center' }}
              />
            </DataTable>
          </div>

          <DialogPr
            visible={deleteProductDialog}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="confirmation-content">
              {cashBox && (
                <span>
                  ¿Confirma eliminar los ticket nro <b>{cashBox.id}?</b>
                </span>
              )}
            </div>
          </DialogPr>
          <DialogPr
            visible={deleteProductsDialog}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Confirm"
            modal
            footer={deleteProductsDialogFooter}
            onHide={hideDeleteProductsDialog}
          >
            <div className="confirmation-content">
              <span>¿Confirma eliminar los ticket seleccionados?</span>
            </div>
          </DialogPr>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ComponentToPrint ticket={cashBox} ref={componentRef} />
                <Button
                  disableElevation
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  onClick={handlePrint}
                  color="mygrey"
                  startIcon={<PrintIcon />}
                >
                  Imprimir
                </Button>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Stack>
      </Container>
    </Page>
  );
}
