import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import { es } from 'date-fns/locale';
import jwtdecode from 'jwt-decode';
import { useReactToPrint } from 'react-to-print';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Tag } from 'primereact/tag';
import '../theme/mystyles.css';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

// material
import {
  Stack,
  Card,
  Container,
  TextField,
  Typography,
  Grid,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
// components
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { createTurn } from '../services/turn';
import { getUserByLoginName } from '../services/user';
import {
  getAllCashBoxByDeadDate,
  getAllCashBoxesByVaucherIdAndState,
  getMinMaxVaucherTurnedNotNull
} from '../services/cashbox';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { sorting } from '../services/utils';
import Page from '../components/Page';
import { getCurrentUser, logout } from '../services/auth.service';
import { getAllStates } from '../services/state';

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const TYPE_TICKET_OPTION = ['Comprobante', 'Cobro'];

// ----------------------------------------------------------------------

export default function CloseBox() {
  const [autoCloseDatePicker, setAutoCloseDatePicker] = useState(true);
  const [cashboxes, setCashBoxes] = useState([]);
  const [states, setStates] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);

  // Formik
  const LoginSchema = Yup.object().shape({
    startDate: Yup.date().nullable().required('Start date is required'),
    endDate: Yup.date().nullable().required('End date is required')
  });

  const formik = useFormik({
    initialValues: {
      tipopago: 'Comprobante',
      startDate: new Date(),
      endDate: new Date(),
      totalAmount: 0,
      totalAmountCount: 0,
      cancelTicketsCount: 0,
      cancelTicketsAmount: 0,
      notWorkTicketsCount: 0,
      notWorkTicketsAmount: 0,
      user: null,
      comprobanteStart: 0,
      comprobanteEnd: 0,
      state: { id: 1, name: 'PAGADO' }
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      switch (data.tipopago) {
        case 'Cobro':
          console.log('es: cobro');
          getAllCashBoxByDeadDate(
            moment.utc(data.startDate).format('YYYY-MM-DD'),
            moment.utc(data.endDate).format('YYYY-MM-DD')
          ).then((items) => {
            console.log(items);
            let canceledTicket = 0;
            let notWorkTicket = 0;
            let paidTicket = 0;
            items.forEach((item) => {
              if (item.state.name === 'ANULADO') {
                canceledTicket += 1;
              }
              if (item.state.name === 'NO_TRABAJO') {
                notWorkTicket += 1;
              }
              if (item.state.name === 'PAGADO') {
                paidTicket += 1;
              }
            });
            console.log(canceledTicket);
            console.log(notWorkTicket);
            console.log(paidTicket);
            setFieldValue('cancelTicketsCount', canceledTicket);
            setFieldValue('notWorkTicketsCount', notWorkTicket);
            setFieldValue('totalAmountCount', paidTicket);
            let suma = 0;
            let sumacancelTicketsAmount = 0;
            let sumanotWorkTicketsAmount = 0;
            items?.forEach((item) => {
              if (item.state.name === 'PAGADO') {
                console.log(item.amount);
                suma += item.amount;
              }
              if (item.state.name === 'ANULADO') {
                console.log(item.amount);
                sumacancelTicketsAmount += item.amount;
              }
              if (item.state.name === 'NO_TRABAJO') {
                console.log(item.amount);
                sumanotWorkTicketsAmount += item.amount;
              }
            });
            console.log(suma);
            console.log(sumacancelTicketsAmount);
            console.log(sumanotWorkTicketsAmount);
            setFieldValue('totalAmount', suma);
            setFieldValue('cancelTicketsAmount', sumacancelTicketsAmount);
            setFieldValue('notWorkTicketsAmount', sumanotWorkTicketsAmount);
            setCashBoxes(sorting(items));
            setSubmitting(false);
          });
          break;
        case 'Comprobante':
          console.log('es por: Comprobante');
          getCashBoxByVaucherNumber();
          break;
        default:
          console.log('es: niguno');
          break;
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, setFieldValue, getFieldProps } =
    formik;

  // GET TYPE USER AND GET MAX MIN VAUCHER NUMBER
  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      const decoded = jwtdecode(user);
      let mounted = true;

      getAllStates().then((items) => {
        if (mounted) {
          console.log(items);
          items.push({
            id: 4,
            name: 'TODOS'
          });
          setStates(items);
        }
      });

      getUserByLoginName(decoded.sub).then((items) => {
        if (mounted) {
          setFieldValue('user', items);
        }
      });

      getMinMaxVaucherTurnedNotNull().then((result) => {
        setFieldValue('comprobanteStart', result.min);
        setFieldValue('comprobanteEnd', result.max);
      });

      return () => (mounted = false);
    }
  }, []);

  // GET VAUCHERS
  useEffect(() => {
    getCashBoxByVaucherNumber();
  }, [formik.values.comprobanteEnd]);

  // PRINT TICKET AND CLOSE
  const componentRef = useRef();
  const printAndCloseSesion = () => {
    handlePrint();
    closeSesion();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const closeSesion = () => {
    logout();
    navigate('/login', { replace: true });
  };

  // CLOSE BOX
  const closeTurn = () => {
    if (values.comprobanteEnd === 0 && values.comprobanteStart === 0) {
      showToast('error', 'Error', 'debe ingresar valores de comprobante');
    } else if (cashboxes.length === 0) {
      showToast('error', 'Error', 'no hay comprobantes para cerrar caja.');
    } else {
      const cashbox = cashboxes.find((cashbox) => cashbox.turn !== null);
      console.log(cashbox);
      if (cashbox) {
        showToast('error', 'Error', 'Existen tickets asociados a otro cierre de caja anterior');
      } else {
        console.log('NO HAY TICKETS');
        console.log(values);
        console.log(values.comprobanteStart);

        let vaucherNumberFrom = null;
        let vaucherNumberEnd = null;

        if (values.tipopago === 'Comprobante') {
          vaucherNumberFrom = values.comprobanteStart;
          vaucherNumberEnd = values.comprobanteEnd;
        }

        const turn = {
          startDate: values.startDate,
          endDate: values.endDate,
          user: values.user,
          voucherNumberFrom: vaucherNumberFrom,
          voucherNumberEnd: vaucherNumberEnd
        };
        console.log(turn);
        createTurn(turn).then((items) => {
          console.log(items);
          printAndCloseSesion();
        });
      }
    }
  };

  const stockBodyTemplate = (rowData) => {
    console.log(rowData.voucherNumber);
    const stockClassName = classNames('font-bold', {
      'text-red-900': rowData.voucherNumber > 0
    });

    return (
      <div className={stockClassName} style={{ fontWeight: 'bold' }}>
        {rowData.voucherNumber}
      </div>
    );
  };

  const getCashBoxByVaucherNumber = () => {
    formik.setSubmitting(true);
    getAllCashBoxesByVaucherIdAndState(
      values.comprobanteStart,
      values.comprobanteEnd,
      values.state.name
    ).then((items) => {
      console.log(items);
      let canceledTicket = 0;
      let notWorkTicket = 0;
      let paidTicket = 0;
      items.forEach((item) => {
        if (item.state.name === 'ANULADO') {
          canceledTicket += 1;
        }
        if (item.state.name === 'NO_TRABAJO') {
          notWorkTicket += 1;
        }
        if (item.state.name === 'PAGADO') {
          paidTicket += 1;
        }
      });
      console.log(canceledTicket);
      console.log(notWorkTicket);
      console.log(paidTicket);
      setFieldValue('cancelTicketsCount', canceledTicket);
      setFieldValue('notWorkTicketsCount', notWorkTicket);
      setFieldValue('totalAmountCount', paidTicket);
      let suma = 0;
      let sumacancelTicketsAmount = 0;
      let sumanotWorkTicketsAmount = 0;
      items?.forEach((item) => {
        if (item.state.name === 'PAGADO') {
          console.log(item.amount);
          suma += item.amount;
        }
        if (item.state.name === 'ANULADO') {
          console.log(item.amount);
          sumacancelTicketsAmount += item.amount;
        }
        if (item.state.name === 'NO_TRABAJO') {
          console.log(item.amount);
          sumanotWorkTicketsAmount += item.amount;
        }
      });
      console.log(suma);
      console.log(sumacancelTicketsAmount);
      console.log(sumanotWorkTicketsAmount);
      setFieldValue('totalAmount', suma);
      setFieldValue('cancelTicketsAmount', sumacancelTicketsAmount);
      setFieldValue('notWorkTicketsAmount', sumanotWorkTicketsAmount);
      setCashBoxes(sorting(items));
      formik.setSubmitting(false);
    });
  };

  const rowClassName = (data) => {
    console.log(data);
    return data.turn?.id ? 'background_turned' : '';
  };

  const currentBodyTemplate = (cashbox) =>
    moment(cashbox.currentData).locale('es').format('DD-MM-YYYY HH:mm');

  const statusBodyTemplate = (cashbox) => (
    <Tag value={cashbox.state.name} className={getStyle(cashbox)} />
  );
  const getStyle = (cashbox) => {
    switch (cashbox.state.name) {
      case 'PAGADO':
        return 'pagado';
      case 'NO_TRABAJO':
        return 'no_trabajo';
      case 'ANULADO':
        return 'anulado';
      default:
        return null;
    }
  };

  const showToast = (severityValue, summaryValue, detailValue) => {
    toast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
  };

  return (
    <Page title="Cierre Caja">
      <Container>
        <HeaderBreadcrumbs heading="Cierre Caja" />
        <Toast ref={toast} />
        <Stack mb={7}>
          <Grid container spacing={3}>
            <Grid item md={7} xs={12}>
              <Card sx={{ py: 5, px: 5 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <FormControl component="fieldset">
                        <RadioGroup {...getFieldProps('tipopago')} row defaultValue="nn">
                          {TYPE_TICKET_OPTION.map((tipopago) => (
                            <FormControlLabel
                              key={tipopago}
                              value={tipopago}
                              control={<Radio />}
                              label={tipopago}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                          {values.tipopago === 'Cobro' ? (
                            <>
                              <MobileDatePicker
                                closeOnSelect={autoCloseDatePicker}
                                label="Desde"
                                {...getFieldProps('startDate')}
                                onChange={(value) => setFieldValue('startDate', value)}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    error={Boolean(touched.startDate && errors.startDate)}
                                  />
                                )}
                                inputFormat="dd/MM/yyyy"
                              />
                              <MobileDatePicker
                                closeOnSelect={autoCloseDatePicker}
                                label="Hasta"
                                {...getFieldProps('endDate')}
                                onChange={(value) => setFieldValue('endDate', value)}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    error={Boolean(touched.endDate && errors.endDate)}
                                  />
                                )}
                                inputFormat="dd/MM/yyyy"
                              />
                            </>
                          ) : null}
                          {values.tipopago === 'Comprobante' ? (
                            <>
                              <TextField
                                type="number"
                                fullWidth
                                label="Desde"
                                {...getFieldProps('comprobanteStart')}
                                error={Boolean(touched.login && errors.login)}
                                helperText={touched.login && errors.login}
                              />
                              <TextField
                                type="number"
                                fullWidth
                                label="Hasta"
                                {...getFieldProps('comprobanteEnd')}
                                error={Boolean(touched.login && errors.login)}
                                helperText={touched.login && errors.login}
                              />
                              <div className="card flex justify-content-center">
                                <Dropdown
                                  {...getFieldProps('state')}
                                  options={states}
                                  optionLabel="name"
                                  placeholder="Seleccione un Estado"
                                  className="w-full md:w-14rem"
                                />
                              </div>
                            </>
                          ) : null}
                        </LocalizationProvider>
                      </Stack>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        size="large"
                      >
                        Consultar
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
            <Grid item md={5} xs={12}>
              <Card
                sx={{ border: 2, mb: 2 }}
                style={{ boxShadow: 'none', padding: 40 }}
                ref={componentRef}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Costo Total:
                </Typography>
                <Stack spacing={2}>
                  <Typography variant="h3">{values.totalAmount} u</Typography>

                  <Divider variant="fullWidth" />

                  {values.tipopago === 'Comprobante' ? (
                    <>
                      <RowStyle>
                        <Typography sx={{ mr: 7 }} variant="subtitle1">
                          Fecha Cierre
                        </Typography>
                        <Card
                          sx={{ border: 2, mb: 0 }}
                          style={{ boxShadow: 'none', paddingInline: 10 }}
                        >
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {moment(values.startDate).locale('es').format('DD-MM-YYYY HH:mm')}
                          </Typography>
                        </Card>
                      </RowStyle>
                      <RowStyle>
                        <Typography sx={{ mr: 7 }} variant="subtitle1">
                          Comprobante Desde
                        </Typography>
                        <Typography variant="subtitle1"> {values.comprobanteStart} </Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography sx={{ mr: 7 }} variant="subtitle1">
                          Comprobante Hasta
                        </Typography>
                        <Typography variant="subtitle1"> {values.comprobanteEnd} </Typography>
                      </RowStyle>
                    </>
                  ) : (
                    <>
                      <RowStyle>
                        <Typography variant="subtitle1">Fecha Desde</Typography>
                        <Typography variant="subtitle1">
                          {moment(values.startDate).locale('es').format('DD-MM-YYYY')}
                        </Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="subtitle1">Fecha Hasta</Typography>
                        <Typography variant="subtitle1">
                          {moment(values.endDate).locale('es').format('DD-MM-YYYY')}
                        </Typography>
                      </RowStyle>
                    </>
                  )}

                  <Divider variant="fullWidth" />
                  {values.state.name === 'TODOS' ? (
                    <>
                      <RowStyle>
                        <Typography variant="body2">Tickets No Trabajó</Typography>
                        <Typography variant="body2">{values.notWorkTicketsCount}</Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="body2">Total No Trabajó</Typography>
                        <Typography variant="body2">{values.notWorkTicketsAmount} u</Typography>
                      </RowStyle>
                      <Divider variant="fullWidth" />
                      <RowStyle>
                        <Typography variant="body2">Tickets Anulados</Typography>
                        <Typography variant="body2">{values.cancelTicketsCount}</Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="body2">Total Anulados</Typography>
                        <Typography variant="body2">{values.cancelTicketsAmount} u</Typography>
                      </RowStyle>
                      <Divider variant="fullWidth" />
                      <RowStyle>
                        <Typography variant="body2">Tickets Cobrados</Typography>
                        <Typography variant="body2">{values.totalAmountCount}</Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="body2">Total Cobrados</Typography>
                        <Typography variant="body2">{values.totalAmount} u</Typography>
                      </RowStyle>
                    </>
                  ) : null}
                  {values.state.name === 'PAGADO' ? (
                    <>
                      <RowStyle>
                        <Typography variant="body2">Tickets Cobrados</Typography>
                        <Typography variant="body2">{values.totalAmountCount}</Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="body2">Total Cobrados</Typography>
                        <Typography variant="body2">{values.totalAmount} u</Typography>
                      </RowStyle>
                    </>
                  ) : null}
                  {values.state.name === 'NO_TRABAJO' ? (
                    <>
                      <RowStyle>
                        <Typography variant="body2">Tickets No Trabajó</Typography>
                        <Typography variant="body2">{values.notWorkTicketsCount}</Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="body2">Total No Trabajó</Typography>
                        <Typography variant="body2">{values.notWorkTicketsAmount} u</Typography>
                      </RowStyle>
                    </>
                  ) : null}
                  {values.state.name === 'ANULADO' ? (
                    <>
                      <RowStyle>
                        <Typography variant="body2">Tickets Anulados</Typography>
                        <Typography variant="body2">{values.cancelTicketsCount}</Typography>
                      </RowStyle>
                      <RowStyle>
                        <Typography variant="body2">Total Anulados</Typography>
                        <Typography variant="body2">{values.cancelTicketsAmount} u</Typography>
                      </RowStyle>
                    </>
                  ) : null}
                </Stack>
              </Card>

              {values.tipopago === 'Comprobante' ? (
                <Stack direction="row" spacing={1.5}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="warning"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    onClick={() => closeTurn()}
                  >
                    CERRAR CAJA
                  </LoadingButton>
                </Stack>
              ) : null}
            </Grid>
          </Grid>
        </Stack>

        <Stack mb={12}>
          <DataTable
            value={cashboxes}
            showGridlines
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '50rem' }}
            size="small"
            sortField="voucherNumber"
            sortOrder={1}
            rowClassName={rowClassName}
          >
            <Column field="voucherNumber" header="Comprobante" sortable body={stockBodyTemplate} />
            <Column field="amount" header="Total" />
            <Column field="user.login" header="Operador" />
            <Column field="mobil.mobilCode" header="Móvil" />
            <Column field="deadTimeDate" header="Fecha Pago" />
            <Column field="currentData" header="Fecha Emisión" body={currentBodyTemplate} />
            <Column field="status.name" header="Estado" body={statusBodyTemplate} />
          </DataTable>
        </Stack>
      </Container>
    </Page>
  );
}
