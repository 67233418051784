import { PureComponent } from 'react';
import { Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import Label from './Label';

const ContentStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(5),
  border: 1,
  // paddingTop: theme.spacing(16),
  color: theme.palette.common.black
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

export class ComponentToPrint extends PureComponent {
  render() {
    const { ticket, type } = this.props;
    console.log(ticket);
    console.log(type);
    return (
      <div>
        {this.props.type === 'closeBox' && ticket.tipopago === 'Comprobante' ? (
          <ContentStyle sx={{ border: 2, mb: 2 }}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              CIERRE DE CAJA
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Total:
            </Typography>

            <Stack spacing={2}>
              <Typography variant="h3">{this.props?.ticket?.totalAmount} u</Typography>

              <RowStyle>
                <Typography sx={{ mr: 7 }} variant="subtitle1">
                  Fecha Cierre
                </Typography>
                <Typography variant="subtitle1">
                  {moment(this.props?.ticket?.startDate).locale('es').format('DD-MM-YYYY HH:mm')}
                </Typography>
              </RowStyle>

              <Divider variant="fullWidth" />

              <RowStyle>
                <Typography sx={{ mr: 7 }} variant="body2">
                  Comprobante Desde
                </Typography>
                <Typography variant="body2"> {this.props?.ticket?.comprobanteStart} </Typography>
              </RowStyle>

              <RowStyle>
                <Typography sx={{ mr: 7 }} variant="body2">
                  Comprobante Hasta
                </Typography>
                <Typography variant="body2"> {this.props?.ticket?.comprobanteEnd} </Typography>
              </RowStyle>

              <Divider variant="fullWidth" />

              <RowStyle>
                <Typography variant="body2">Tickets No Trabajó</Typography>
                <Typography variant="body2">{this.props?.ticket?.notWorkTicketsCount}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Total No Trabajó</Typography>
                <Typography variant="body2">{this.props?.ticket?.notWorkTicketsAmount}</Typography>
              </RowStyle>

              <Divider variant="fullWidth" />

              <RowStyle>
                <Typography variant="body2">Tickets Anulados</Typography>
                <Typography variant="body2">{this.props?.ticket?.cancelTicketsCount}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Total Anulados</Typography>
                <Typography variant="body2">{this.props?.ticket?.cancelTicketsAmount}</Typography>
              </RowStyle>

              <Divider variant="fullWidth" />

              <RowStyle>
                <Typography variant="body2">Tickets Cobrados</Typography>
                <Typography variant="body2">{this.props?.ticket?.totalAmountCount}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Total Cobrados</Typography>
                <Typography variant="body2">{this.props?.ticket?.totalAmount}</Typography>
              </RowStyle>
            </Stack>
          </ContentStyle>
        ) : null}
        {this.props.type === 'closeBox' && ticket.tipopago !== 'Comprobante' ? (
          <ContentStyle sx={{ border: 2, mb: 2 }}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              CIERRE DE CAJA
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Costo Total:
            </Typography>

            <Stack spacing={2}>
              <Typography variant="h3">{this.props?.ticket?.totalAmount} u</Typography>

              <RowStyle>
                <Typography variant="subtitle1">Tipo</Typography>
                <Typography variant="subtitle1">{this.props?.ticket?.tipopago}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography sx={{ mr: 7 }} variant="body2">
                  Fecha Desde
                </Typography>
                <Typography variant="body2">
                  {moment(this.props?.ticket?.startDate).locale('es').format('DD-MM-YYYY')}
                </Typography>
              </RowStyle>

              <RowStyle>
                <Typography sx={{ mr: 7 }} variant="body2">
                  Fecha Hasta
                </Typography>
                <Typography variant="body2">
                  {moment(this.props?.ticket?.endDate).locale('es').format('DD-MM-YYYY')}
                </Typography>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Tickets Anulados:</Typography>
                <Typography variant="body2">{this.props?.ticket?.cancelTicketsCount}</Typography>
              </RowStyle>
            </Stack>
          </ContentStyle>
        ) : null}
        {type !== 'closeBox' ? (
          <ContentStyle sx={{ border: 2, mb: 2 }}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              COBRO DE FRECUENCIA
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
              Costo Total:
            </Typography>

            <Stack spacing={2}>
              <Typography variant="h3">{this.props.ticket?.amount} u</Typography>
              <RowStyle>
                <Typography variant="subtitle1">Operador</Typography>
                <Typography variant="subtitle1">{this.props.ticket?.user?.login}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Comprobante</Typography>
                <Typography variant="body2">{this.props.ticket?.voucherNumber}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Nro. Móvil</Typography>
                <Typography variant="body2">{this.props.ticket?.mobil?.mobilCode}</Typography>
              </RowStyle>

              <RowStyle>
                <Typography sx={{ mr: 3 }} variant="body2">
                  Fecha Emisión
                </Typography>
                <Label variant="filled" color="mygrey">
                  <Typography variant="body2">
                    {moment(this.props.ticket?.currentData).locale('es').format('llll')}
                  </Typography>{' '}
                </Label>
              </RowStyle>

              <RowStyle>
                <Typography variant="body2">Fecha Pago</Typography>
                <Typography variant="body2">
                  {moment(this.props.ticket?.deadTimeDate).locale('es').format('DD-MM-YYYY')}
                </Typography>
              </RowStyle>
            </Stack>
          </ContentStyle>
        ) : null}
      </div>
    );
  }
}
