import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// material
import { Stack, TextField, InputAdornment, Checkbox, FormControlLabel, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tag from '@mui/icons-material/Tag';
import { createMobil, updateMobil } from '../../services/mobil';

// ----------------------------------------------------------------------
MobilForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object
};

export default function MobilForm({ isEdit, currentUser }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  console.log(isEdit);
  console.log(currentUser);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    mobilCode: Yup.number().nullable().required('Código Móvil es Requerido')
  });

  const formik = useFormik({
    initialValues: {
      id: currentUser?.id || null,
      carPatent: currentUser?.carPatent || '',
      colour: currentUser?.colour || '',
      driveLicence: currentUser?.driveLicence || '',
      mobilCode: currentUser?.mobilCode || null,
      model: currentUser?.model || '',
      name: currentUser?.name || '',
      owner: currentUser?.owner || '',
      trademark: currentUser?.trademark || '',
      specialChargement: currentUser?.specialChargement || false,
      enabled: currentUser?.enabled || true
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      if (isEdit) {
        // Update New Mobil
        console.log('editando movil');
        updateMobil(data).then((items) => {
          console.log(items);
          navigate('/dashboard/mobils', { replace: true });
          enqueueSnackbar('Móvil Actualizado!', {
            variant: 'success'
          });
          setSubmitting(false);
        });
        // resetForm();
      } else {
        // Create New Mobil
        console.log('guardando:');
        createMobil(data).then((items) => {
          console.log(items);
          navigate('/dashboard/mobils', { replace: true });
          enqueueSnackbar('Móvil creado!', {
            variant: 'success'
          });
          setSubmitting(false);
        });
        // resetForm();
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card sx={{ py: 5, px: 5 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  type="number"
                  fullWidth
                  label="Número Móvil"
                  {...getFieldProps('mobilCode')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tag />
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.mobilCode && errors.mobilCode)}
                  helperText={touched.mobilCode && errors.mobilCode}
                />
                <TextField
                  fullWidth
                  label="Patente"
                  {...getFieldProps('carPatent')}
                  error={Boolean(touched.carPatent && errors.carPatent)}
                  helperText={touched.carPatent && errors.carPatent}
                />
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Licencia de Conducir"
                  {...getFieldProps('driveLicence')}
                  error={Boolean(touched.driveLicence && errors.driveLicence)}
                  helperText={touched.driveLicence && errors.driveLicence}
                />
                <TextField
                  fullWidth
                  label="Nombre Móvil"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  label="Modelo"
                  {...getFieldProps('model')}
                  error={Boolean(touched.model && errors.model)}
                  helperText={touched.model && errors.model}
                />
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Color"
                  {...getFieldProps('colour')}
                  error={Boolean(touched.colour && errors.colour)}
                  helperText={touched.colour && errors.colour}
                />
                <TextField
                  fullWidth
                  label="Propietario"
                  {...getFieldProps('owner')}
                  error={Boolean(touched.owner && errors.owner)}
                  helperText={touched.owner && errors.owner}
                />
                <TextField
                  fullWidth
                  label="Marca"
                  {...getFieldProps('trademark')}
                  error={Boolean(touched.trademark && errors.trademark)}
                  helperText={touched.trademark && errors.trademark}
                />
              </Stack>
            </Stack>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <FormControlLabel
                  label="Cobro Diferenciado"
                  control={
                    <Checkbox
                      {...getFieldProps('specialChargement')}
                      checked={values.specialChargement}
                    />
                  }
                />
              </Stack>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <FormControlLabel
                  label="Habilitado"
                  control={<Checkbox {...getFieldProps('enabled')} checked={values.enabled} />}
                />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              size="large"
            >
              {!isEdit ? 'Crear Mobil' : 'Guardar Cambios'}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
