import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Charge from './pages/Charge';
import Mobils from './pages/Mobil/Mobils';
import { MobilForm } from './components/forms';
import MovilCreate from './pages/Mobil/MovilCreate';
import CloseBox from './pages/CloseBox';
import CancelTicket from './pages/CancelTicket';
import Settings from './pages/Settings';
import CashBoxes from './pages/CashBox/CashBoxes';
import CashBoxForm from './pages/CashBox/CashBoxForm';
import CashBoxCreate from './pages/CashBox/CashBoxCreate';
import Users from './pages/User/Users';
import UserForm from './pages/User/UserForm';
import UserCreate from './pages/User/UserCreate';
import MobilsState from './pages/MobilState/MobilsState';
import UserPassword from './pages/User/UserPassword';
import UserPasswordForm from './pages/User/UserPasswordForm';
import NotLogin from './pages/NotLogin';
import NotAuthorized from './pages/NotAuthorized';
import { ComponentToPrint } from './components/ComponentToPrint';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/login" replace /> },
        { path: 'cobro', element: <Charge /> },
        { path: 'mobils', element: <Mobils /> },
        { path: 'mobil', element: <MobilForm /> },
        { path: 'createmobil', element: <MovilCreate /> },
        { path: 'closebox', element: <CloseBox /> },
        { path: 'cancelticket', element: <CancelTicket /> },
        { path: 'settings', element: <Settings /> },
        { path: 'cashboxes', element: <CashBoxes /> },
        { path: 'cashbox', element: <CashBoxForm /> },
        { path: 'createcashbox', element: <CashBoxCreate /> },
        { path: 'users', element: <Users /> },
        { path: 'user', element: <UserForm /> },
        { path: 'createuser', element: <UserCreate /> },
        { path: 'statemobils', element: <MobilsState /> },
        { path: 'userpassword', element: <UserPassword /> },
        { path: 'userpasswordform', element: <UserPasswordForm /> },
        { path: 'componenttoprint', element: <ComponentToPrint /> }
      ]
    },
    {
      path: '/',
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        // { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'notlogin', element: <NotLogin replace /> },
        { path: 'notauthorized', element: <NotAuthorized replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
