import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { es } from 'date-fns/locale';

// material
import {
  Stack,
  TextField,
  InputAdornment,
  Card,
  Autocomplete,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getAllMobils } from '../../services/mobil';
import { getPublicAllSettings } from '../../services/settings';
import { createCashBox, updateCashBoxById } from '../../services/cashbox';
import { getAllStates } from '../../services/state';
import { getAllUsers } from '../../services/user';

// ----------------------------------------------------------------------
CashBoxForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object
};

moment.locale('us');

export default function CashBoxForm({ isEdit, currentUser }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [autoCloseDatePicker, setAutoCloseDatePicker] = useState(true);

  console.log(currentUser);

  const LoginSchema = Yup.object().shape({
    amount: Yup.number().nullable().required('Costo es Requerido'),
    deadTimeDate: Yup.date().nullable().required('Fecha Pago es Requerido'),
    currentData: Yup.date().nullable().required('Fecha Emision es Requerido'),
    mobil: Yup.object().nullable().required('Movil es Requerido'),
    state: Yup.object().nullable().required('Estado es Requerido'),
    user: Yup.object().nullable().required('Usuario es Requerido')
  });

  const formik = useFormik({
    initialValues: {
      id: currentUser?.id || null,
      currentData: currentUser?.currentData || Date(),
      deadTimeDate: currentUser?.deadTimeDate || null,
      mobil: currentUser?.mobil || null,
      operator: currentUser?.operator || null,
      amount: currentUser?.amount || '',
      cancelled: currentUser?.cancelled || false,
      cancelledBy: currentUser?.cancelledBy || null,
      state: currentUser?.state || null,
      user: currentUser?.user || null,
      disableValidation: false
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      if (isEdit) {
        // Update New Cashbox
        console.log('editando Cashbox');
        updateCashBoxById(data).then((items) => {
          console.log(items);
          navigate('/dashboard/cashboxes', { replace: true });
          enqueueSnackbar('Ticket Actualizado!', {
            variant: 'success'
          });
          setSubmitting(false);
        });
        resetForm();
      } else {
        // Create New Cashbox
        console.log('guardando Cashbox');
        data.currentData = moment.utc(data.currentData).format();
        console.log(data);

        createCashBox(data)
          .then((response) => {
            console.log(response);
            navigate('/dashboard/cashboxes', { replace: true });
            if (response.code === 'NOK') {
              enqueueSnackbar(`Tickets NO creado: ${response.message}`, {
                variant: 'error'
              });
            } else {
              enqueueSnackbar(`${response.data.message}`, {
                variant: 'success'
              });
            }
            resetForm();
            setSubmitting(false);
          })
          .catch((error) => {
            const { response } = error;
            console.log(response);
            console.log(response.data);
            if (
              (response.data.code === 'NOK' &&
                response.data.error?.includes('unique_mobilid_deadtimedate')) ||
              response.data.detail?.includes('unique_mobilid_deadtimedate')
            ) {
              enqueueSnackbar('Error: Ya existe este cobro', {
                variant: 'error'
              });
            } else if (response.data.code === 'NOK') {
              enqueueSnackbar(`Error: ${response.data.message}`, {
                variant: 'error'
              });
            } else {
              enqueueSnackbar(`Error: ${response.data.detail}`, {
                variant: 'error'
              });
            }
            setSubmitting(false);
          });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    handleBlur
  } = formik;

  // GET ALL MOBILS
  const [mobils, setMobils] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllMobils().then((items) => {
      if (mounted) {
        const mobils = items.sort((a, b) => a.mobilCode - b.mobilCode);
        setMobils(mobils);
      }
    });
    return () => (mounted = false);
  }, []);

  // GET ALL USERS
  const [users, setUsers] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllUsers().then((items) => {
      if (mounted) {
        setUsers(items);
      }
    });
    return () => (mounted = false);
  }, []);

  // GET ALL STATES
  const [states, setStates] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllStates().then((items) => {
      if (mounted) {
        setStates(items);
      }
    });
    return () => (mounted = false);
  }, []);

  const fixDate = (date) => {
    console.log(date);
    const newDate = moment(date, 'YYYY-MM-DD').utc().format('YYYY-MM-DD');
    console.log(newDate);
    setFieldValue('deadTimeDate', newDate);
  };

  // GET SETTING
  const [setting, setSettings] = useState();
  useEffect(() => {
    let mounted = true;
    getPublicAllSettings().then((items) => {
      if (mounted) {
        setSettings(items[0]);
        console.log(items[0]);
      }
    });
    return () => (mounted = false);
  }, []);

  const updateMobilSelected = (value) => {
    console.log(value);
    setFieldValue('mobil', value || null);
    if (value != null) {
      const amount = value.specialChargement ? setting.importTwo : setting.importOne;
      setFieldValue('amount', amount);
    }
  };

  const setState = (value) => {
    setFieldValue('state', value || null);
    if (value != null && value.name === 'ANULADO') {
      setFieldValue('cancelled', true);
      setFieldValue('cancelledBy', values.user.login);
    }
  };

  return (
    <Card sx={{ py: 5, px: 5 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <FormControlLabel
                  sx={{ mb: 2 }}
                  label="Desactivar Validaciones"
                  control={
                    <Checkbox
                      {...getFieldProps('disableValidation')}
                      checked={values.disableValidation}
                    />
                  }
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={3}>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <Autocomplete
                  id="contact-autocomplete"
                  fullWidth
                  options={mobils}
                  getOptionLabel={(option) => option?.mobilCode?.toString()}
                  {...getFieldProps('mobil')}
                  onChange={(e, value) => updateMobilSelected(value || null)}
                  onOpen={handleBlur}
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nro. Móvil"
                      name="mobil"
                      error={Boolean(touched.mobil && errors.mobil)}
                      helperText={touched.mobil && errors.mobil}
                    />
                  )}
                />
                <TextField
                  type="number"
                  fullWidth
                  label="Costo"
                  {...getFieldProps('amount')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
              </Stack>
            </Stack>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    closeOnSelect={autoCloseDatePicker}
                    label="Fecha de Pagos"
                    value={moment(getFieldProps('deadTimeDate').value, 'YYYY-MM-DD HH:mm')
                      .utc()
                      .format('YYYY-MM-DD HH:mm')}
                    onChange={(date) => fixDate(date)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={Boolean(touched.deadTimeDate && errors.deadTimeDate)}
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                  <MobileDateTimePicker
                    closeOnSelect={autoCloseDatePicker}
                    label="Fecha de Emisión"
                    {...getFieldProps('currentData')}
                    onChange={(date) => setFieldValue('currentData', date)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={Boolean(touched.currentData && errors.currentData)}
                      />
                    )}
                    inputFormat="dd/MM/yyyy HH:mm "
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <Autocomplete
                  id="movil-autocomplete"
                  fullWidth
                  options={users}
                  getOptionLabel={(option) => option?.login?.toString()}
                  {...getFieldProps('user')}
                  onChange={(e, value) => setFieldValue('user', value || null)}
                  onOpen={handleBlur}
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuario"
                      name="user"
                      error={Boolean(touched.user && errors.user)}
                      helperText={touched.user && errors.user}
                    />
                  )}
                />
                <Autocomplete
                  id="state-autocomplete"
                  fullWidth
                  options={states}
                  getOptionLabel={(option) => option?.name?.toString()}
                  {...getFieldProps('state')}
                  onChange={(e, value) => setState(value)}
                  onOpen={handleBlur}
                  includeInputInList
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado"
                      name="Estado"
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  )}
                />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              size="large"
            >
              {!isEdit ? 'Crear Ticket' : 'Guardar Cambios'}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
