import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, getIn, Field } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
// material
import { Stack, TextField, InputAdornment, Checkbox, FormControlLabel, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import { createUser, updateUser } from '../../services/user';

// ----------------------------------------------------------------------
UserForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object
};

export default function UserForm({ isEdit, currentUser }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  console.log(currentUser);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    login: Yup.string().nullable().required('El Usuario es Requerido'),
    firstName: Yup.string().nullable().required('El Nombre del Usuario es Requerido'),
    lastName: Yup.string().nullable().required('El Nombre del Usuario es Requerido'),
    email: Yup.string().email().nullable().required('El Nombre del Usuario es Requerido'),
    password: Yup.string()
      .nullable()
      .when('isEditar', {
        is: true,
        then: Yup.string().required('La Contraseña es Requerida')
      }),
    activated: Yup.boolean().nullable().required('Bloqueado es Requerido')
  });

  const formik = useFormik({
    initialValues: {
      id: currentUser?.id || null,
      admin: currentUser?.admin || false,
      activated: currentUser?.activated || false,
      password: currentUser?.password || '',
      login: currentUser?.login || '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      authorities: currentUser?.authorities || ['ROLE_USER'],
      isEditar: !isEdit
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      if (isEdit) {
        // Update New Mobil
        console.log('editando usuario');
        updateUser(data).then((items) => {
          console.log(items);
          navigate('/dashboard/users', { replace: true });
          enqueueSnackbar('Usuario Actualizado!', {
            variant: 'success'
          });
          setSubmitting(false);
          resetForm();
        });
      } else {
        // Create New User
        console.log('guardando new user:');
        console.log(data);
        createUser(data)
          .then((res) => {
            console.log(res);
            if (res.status === 201) {
              navigate('/dashboard/users', { replace: true });
              enqueueSnackbar('Usuario Creado!', {
                variant: 'success'
              });
            }
            setSubmitting(false);
            resetForm();
          })
          .catch((error) => {
            const { response } = error;
            console.log(response);
            if (
              response.data.status === 400 &&
              response.data.title === 'Login name already used!'
            ) {
              enqueueSnackbar('Error: Ya existe el usuario que quiere crear', {
                variant: 'error'
              });
            } else {
              enqueueSnackbar(response.data.title, {
                variant: 'error'
              });
            }
            setSubmitting(false);
          });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    handleBlur
  } = formik;

  useEffect(() => {
    console.log('useffect');
    if (isEdit) {
      console.log('EDIT');
      const role = currentUser?.authorities[0] === 'ROLE_ADMIN' ? 'ROLE_ADMIN' : 'ROLE_USER';
      const isAdmin = currentUser?.authorities[0] === 'ROLE_ADMIN';
      setFieldValue('authorities', [role]);
      setFieldValue('admin', isAdmin);
    } else {
      console.log('NOT EDIT');
    }
  }, []);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const updateMobil = (value) => {
    console.log(value);
    setFieldValue('admin', value || null);
    const role = value ? 'ROLE_ADMIN' : 'ROLE_USER';
    setFieldValue('authorities', [role]);
  };

  return (
    <Card sx={{ py: 5, px: 5 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Usuario"
                  {...getFieldProps('login')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.login && errors.login)}
                  helperText={touched.login && errors.login}
                />
                {!isEdit && (
                  <TextField
                    fullWidth
                    label="Contraseña"
                    {...getFieldProps('password')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                )}
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Nombre"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  fullWidth
                  label="Apellido"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <FormControlLabel
                  label="Administrador"
                  control={
                    <Checkbox
                      {...getFieldProps('admin')}
                      checked={values.admin}
                      onChange={(e, value) => updateMobil(value)}
                    />
                  }
                />
              </Stack>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <FormControlLabel
                  label="Activado"
                  control={<Checkbox {...getFieldProps('activated')} checked={values.activated} />}
                />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              size="large"
            >
              {!isEdit ? 'Crear Usuario' : 'Guardar Cambios'}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
