import instance from './api';

export const URL = process.env.REACT_APP_API_URI;

export function getAllCashBox() {
  return instance.get(`${URL}/api/cash-boxes`);
}

export function getCashBoxById(id) {
  return instance.get(`${URL}/api/cash-boxes/${id}`);
}

export function createCashBox(cashBox) {
  return instance.post(`${URL}/api/cash-boxes`, cashBox);
}

export function createCashBoxByRangeDays(cashBox) {
  return instance.post(`${URL}/api/cash-boxes-by-dates`, cashBox);
}

export function getAllCashBoxByDeadDate(date1, date2) {
  return instance.get(`${URL}/api/cash-boxes/deaddate/${date1}/${date2}`);
}

export function getAllCashBoxByVaucherId(start, end) {
  return instance.get(`${URL}/api/cash-boxes/vaucherid/${start}/${end}`);
}

export function getAllCashBoxesByVaucherIdAndState(start, end, stateName) {
  return instance.get(`${URL}/api/cash-boxes/vaucherid/${start}/${end}/${stateName}`);
}

export function getAllCashBoxByCurrentDate(date1, date2) {
  return instance.get(`${URL}/api/cash-boxes/currentdate/${date1}/${date2}`);
}

export function getAllCashBoxCanceled() {
  return instance.get(`${URL}/api/cash-boxes/canceled`);
}

export function getCashBoxesByMobilAndDeadTimeDate(cashBox) {
  return instance.post(`${URL}/api/cash-boxes/getcashboxesbymobilanddeadtimedate`, cashBox);
}

export function getCashBoxByMobilAndDeadTimeDate(cashBox) {
  return instance.post(`${URL}/api/cash-boxes/getcashboxbymobilanddeadtimedate`, cashBox);
}

export function updateCashBoxById(cashBox) {
  return instance.put(`${URL}/api/cash-boxes/${cashBox.id}`, cashBox);
}

export function getCashBoxByMobilAndBetweenDeadTimeDate(cashBox) {
  return instance.post(`${URL}/api/cash-boxes/getcashboxesbymobilandbetweendeadtimedate`, cashBox);
}

export function deleteCashBoxById(id) {
  return instance.delete(`${URL}/api/cash-boxes/${id}`);
}

export function getAllCashBoxCanceledByName() {
  return instance.get(`${URL}/api/cash-boxes/canceledbystatename`);
}

export function findFirstByOrderByVoucherNumberDesc() {
  return instance.get(`${URL}/api/cash-boxes/findfirstbyorderbyvouchernumberdesc`);
}

export function getMinMaxVaucherTurnedNotNull() {
  return instance.get(`${URL}/api/cash-boxes/getminmaxvaucherturnednotnull`);
}

export function deleteCashBoxes(cashBoxes) {
  return instance.post(`${URL}/api/cash-boxes/`, cashBoxes);
}

export function getCashBoxByVaucherId(vaucherid) {
  return instance.get(`${URL}/api/cash-boxes/vaucherid/${vaucherid}`);
}
