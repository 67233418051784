import instance from './api';

export const URL = process.env.REACT_APP_API_URI;

export function getAllUsers() {
  return instance.get(`${URL}/api/admin/users`);
}

export function getUserByLoginName(loginName) {
  return instance.get(`${URL}/api/users/${loginName}`);
}

export function createTurn(turn) {
  return instance.post(`${URL}/api/turns`, turn);
}

export function deleteUser(username) {
  return instance.delete(`${URL}/api/admin/users/${username}`);
}

export function updateUser(user) {
  return instance.put(`${URL}/api/admin/users`, user);
}

export function updateUserPassword(user) {
  return instance.post(`${URL}/api/admin/users/userspassword`, user);
}
