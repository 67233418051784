import { Icon } from '@iconify/react';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import carfilled from '@iconify/icons-ic/round-directions-car';
import baseline from '@iconify/icons-ic/round-monetization-on';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import checkMarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfigRoleUser = [
  {
    subheader: '',
    items: [
      {
        title: 'cobro frecuencia',
        path: '/dashboard/cobro',
        icon: getIcon(baseline),
        role: 'admin'
      },
      {
        title: 'Cierre Caja',
        path: '/dashboard/closebox',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Anular Ticket',
        path: '/dashboard/cancelticket',
        icon: getIcon(closeCircleFill)
      },
      {
        title: 'Estado Móviles',
        path: '/dashboard/statemobils',
        icon: getIcon(checkMarkCircle2Fill)
      },
      {
        title: 'Móviles',
        path: '/dashboard/mobils',
        icon: getIcon(carfilled)
      }
    ]
  }
];

export default sidebarConfigRoleUser;
