import instance from './api';

export const URL = process.env.REACT_APP_API_URI;

export function getAllOperators() {
  return instance.get(`${URL}/api/operators`);
}

export function getOperatorById(id) {
  return instance.get(`${URL}/api/operators/${id}`);
}

export function createOperator(operator) {
  return instance.post(`${URL}/api/operators`, operator);
}

export function deleteOperator(id) {
  return instance.delete(`${URL}/api/operators/${id}`);
}

export function updateOperator(operator) {
  return instance.put(`${URL}/api/operators/${operator.id}`, operator);
}
