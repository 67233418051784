import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import PrintIcon from '@mui/icons-material/Print';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Switch,
  Grid
} from '@mui/material';

const RootStyle = styled(Toolbar)(({ theme, side }) => ({
  height: 96,
  display: 'flex',
  justifyContent: side,
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const StatusMobilesToolbar = ({
  filterName,
  onFilterName,
  nameSearchPlaceHolder,
  handleClick,
  handlePrint
}) => {
  StatusMobilesToolbar.propTypes = {
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
    nameSearchPlaceHolder: PropTypes.string,
    handleClick: PropTypes.func,
    handlePrint: PropTypes.func
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleClick(event.target.checked);
  };

  const handleClink = () => {
    handlePrint();
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <RootStyle side="left">
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder={nameSearchPlaceHolder}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          </RootStyle>
        </Grid>
        <Grid item xs={6} md={4}>
          <RootStyle side="right">
            <Tooltip title="Ver móbiles al día">
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Tooltip>

            <Tooltip title="Imprimir">
              <IconButton onClick={handleClink}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
          </RootStyle>
        </Grid>
      </Grid>
    </div>
  );
};

export default StatusMobilesToolbar;
