import { filter } from 'lodash';
import { useState, useRef } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { es } from 'date-fns/locale';
import moment from 'moment';
// material
import {
  Card,
  Container,
  TableContainer,
  Stack,
  Grid,
  TextField,
  Typography,
  Paper,
  Box,
  Badge
} from '@mui/material';
// components
import { useReactToPrint } from 'react-to-print';
import { LoadingButton } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { getAllStateMobilsByDate } from '../../services/mobil-states';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import StatusMobilesToolbar from '../../components/_dashboard/user/StatusMobilesToolbar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      // (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      (_user) => parseInt(_user.mobilCode, 10) === parseInt(query, 10)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MobilsState() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('mobilCode');
  const [filterName, setFilterName] = useState('');
  const [mobilsState, setMobilsState] = useState([]);
  const [stateMobilsDets, setStateMobilsDets] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  // Formik
  const LoginSchema = Yup.object().shape({
    deadTimeDate: Yup.date().nullable().required('End date is required')
  });

  const formik = useFormik({
    initialValues: {
      deadTimeDate: new Date()
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      getAllStateMobilsByDate(moment.utc(data.deadTimeDate).format('YYYY-MM-DD')).then(
        (cashbox) => {
          console.log(cashbox.mobils);
          setMobilsState(cashbox.mobils);
          enqueueSnackbar('Consulta de estados finalizada', {
            variant: 'success'
          });
          setSubmitting(false);
        }
      );
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, setFieldValue, getFieldProps } = formik;

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    mobilsState.map((mobil) => ({
      id: mobil.mobil.id,
      name: mobil.mobil.name,
      mobilCode: mobil.mobil.mobilCode,
      state: mobil.state,
      debtDays: mobil.debtDays
    })),
    getComparator(order, orderBy),
    filterName
  );

  const handleClick = (state) => {
    setStateMobilsDets(state);
  };

  return (
    <Page title="Móviles">
      <Container>
        <HeaderBreadcrumbs heading="Estado Móviles" />

        <Stack mb={12}>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
              <Card sx={{ py: 5, px: 5 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            label="Fecha"
                            {...getFieldProps('deadTimeDate')}
                            onChange={(date) => setFieldValue('deadTimeDate', date)}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                error={Boolean(touched.deadTimeDate && errors.deadTimeDate)}
                              />
                            )}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                      </Stack>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        size="large"
                      >
                        Consultar Estado de Móviles
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
          </Grid>
        </Stack>

        <Stack mb={12}>
          <Card sx={{ py: 4, px: 4 }}>
            <StatusMobilesToolbar
              nameSearchPlaceHolder="Buscar Móvil"
              filterName={filterName}
              onFilterName={handleFilterByName}
              handleClick={handleClick}
              handlePrint={handlePrint}
            />

            <Scrollbar>
              <TableContainer ref={componentRef} sx={{ minWidth: 800 }}>
                <Typography align="center" variant="h6" marginBottom={5}>
                  Fecha reporte: {moment(formik.values.deadTimeDate).format('DD/MM/YYYY')}
                </Typography>

                {filteredUsers.map((row) => {
                  const { id, mobilCode, debtDays } = row;

                  return (
                    <Badge
                      color="secondary"
                      badgeContent={debtDays}
                      overlap="circular"
                      sx={{
                        '& .MuiBadge-badge': {
                          fontSize: 16,
                          height: 23,
                          minWidth: 23,
                          borderRadius: 10
                        }
                      }}
                      key={id} // Don't forget to add a unique key
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          '& > :not(style)': {
                            m: 0.9,
                            width: 45,
                            height: 45
                          }
                        }}
                      >
                        {(stateMobilsDets || debtDays) && (
                          <Paper
                            component={Stack}
                            direction="column"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            style={{ backgroundColor: debtDays ? 'white' : '#2065D1' }}
                            variant="outlined"
                          >
                            <Typography
                              variant="h6"
                              color={debtDays ? 'common.black' : 'common.white'}
                            >
                              {mobilCode}
                            </Typography>
                          </Paper>
                        )}
                      </Box>
                    </Badge>
                  );
                })}
              </TableContainer>
            </Scrollbar>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
