import * as Yup from 'yup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import jwtdecode from 'jwt-decode';

// material
import { Stack, TextField, InputAdornment, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import { updateUserPassword } from '../../services/user';
import { getCurrentUser } from '../../services/auth.service';

// ----------------------------------------------------------------------
UserPasswordForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object
};

export default function UserPasswordForm({ isEdit, currentUser }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  console.log(currentUser);
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    login: Yup.string().nullable().required('El Usuario es Requerido'),
    currentPassword: Yup.string()
      .nullable()
      .when('fromAdminCount', {
        is: false,
        then: Yup.string().required('La Contraseña actual es Requerida')
      })
      .min(4, 'La contraseña actual debe tener un mínimo de 4 digitos')
      .max(100, 'La contraseña actual debe tener un máximo de 100 digitos'),
    newPassword: Yup.string()
      .nullable()
      .required('Debe ingresar la nueva contraseña')
      .min(4, 'La nueva contraseña debe tener un mínimo de 4 digitos')
      .max(100, 'La nueva contraseña debe tener un máximo de 100 digitos'),
    newPasswordRepeat: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        'Las nueva contraseña y su confirmación deben ser iguales'
      )
      .required('Debe confirmar la nueva contraseña')
  });

  const formik = useFormik({
    initialValues: {
      login: currentUser?.login || '',
      currentPassword: currentUser?.currentPassword || '',
      newPassword: currentUser?.newPassword || '',
      newPasswordRepeat: currentUser?.newPasswordRepeat || '',
      fromAdminCount: isEdit
    },
    validationSchema: LoginSchema,
    onSubmit: (data, { setSubmitting, resetForm }) => {
      console.log(data);
      console.log('change/reset currentPassword');
      updateUserPassword(data)
        .then((items) => {
          console.log(items);
          const pageToNavigate = isEdit ? '/dashboard/users' : '/dashboard/cobro';
          navigate(pageToNavigate, { replace: true });
          enqueueSnackbar('Contraseña Actualizada!', {
            variant: 'success'
          });
          setSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          const { response } = error;
          console.log(response);
          if (response.data.status === 500 && response.data.detail === 'Incorrect password') {
            enqueueSnackbar('Error: La contraseña actual es incorrecta.', {
              variant: 'error'
            });
          } else {
            enqueueSnackbar(response.data.title, {
              variant: 'error'
            });
          }
          setSubmitting(false);
        });
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    handleBlur
  } = formik;

  useEffect(() => {
    console.log('useffect');
    console.log(currentUser);
    if (!currentUser?.login) {
      console.log('NOT ADMIN');
      const user = getCurrentUser();
      const { sub } = jwtdecode(user);
      if (sub) {
        setFieldValue('login', sub);
      }
    } else {
      console.log('ADMIN');
      setFieldValue('login', currentUser.login);
    }
  }, [isEdit]);

  return (
    <Card sx={{ py: 5, px: 5 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  disabled
                  fullWidth
                  label="Usuario"
                  {...getFieldProps('login')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.login && errors.login)}
                  helperText={touched.login && errors.login}
                />
              </Stack>
            </Stack>

            {!isEdit && (
              <Stack spacing={0}>
                <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                  <TextField
                    fullWidth
                    label="Contraseña Actual"
                    {...getFieldProps('currentPassword')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.currentPassword && errors.currentPassword)}
                    helperText={touched.currentPassword && errors.currentPassword}
                  />
                </Stack>
              </Stack>
            )}

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Nueva Contraseña"
                  {...getFieldProps('newPassword')}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </Stack>
            </Stack>

            <Stack spacing={0}>
              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Confirmar Nueva Contraseña"
                  {...getFieldProps('newPasswordRepeat')}
                  error={Boolean(touched.newPasswordRepeat && errors.newPasswordRepeat)}
                  helperText={touched.newPasswordRepeat && errors.newPasswordRepeat}
                />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              size="large"
            >
              {isEdit ? 'Resetear Contraseña' : 'Cambiar Contraseña'}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
