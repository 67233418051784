import { Icon } from '@iconify/react';
import personFill from '@iconify/icons-eva/person-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import carfilled from '@iconify/icons-ic/round-directions-car';
import baseline from '@iconify/icons-ic/round-monetization-on';
import settingsFill from '@iconify/icons-eva/settings-fill';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import bookFill from '@iconify/icons-eva/book-fill';
import checkMarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'Estado Móviles',
        path: '/dashboard/statemobils',
        icon: getIcon(checkMarkCircle2Fill)
      },
      {
        title: 'cobro frecuencia',
        path: '/dashboard/cobro',
        icon: getIcon(baseline)
      },
      {
        title: 'Cierre Caja',
        path: '/dashboard/closebox',
        icon: getIcon(shoppingBagFill)
      },
      {
        title: 'Anular Ticket',
        path: '/dashboard/cancelticket',
        icon: getIcon(closeCircleFill)
      },
      {
        title: 'Registros',
        path: '/dashboard/cashboxes',
        icon: getIcon(bookFill)
      },
      {
        title: 'Móviles',
        path: '/dashboard/mobils',
        icon: getIcon(carfilled)
      },
      {
        title: 'Usuarios',
        path: '/dashboard/users',
        icon: getIcon(personFill)
      },
      {
        title: 'Configuración',
        path: '/dashboard/settings',
        icon: getIcon(settingsFill)
      }
    ]
  }
];

export default sidebarConfig;
