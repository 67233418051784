import { createBrowserHistory } from 'history';
import axios from 'axios';
import authHeader from './auth-header';

export const URL = process.env.REACT_APP_API_URI;

const history = createBrowserHistory();

const instance = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = authHeader();
    if (config.url !== `${URL}/api/authenticate` && token) {
      console.log('NO LOGIN Y HAY TOKEN');
      config.headers.Authorization = `${token.Authorization}`;
    } else {
      console.log('ES LOGIN');
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    console.log(res);
    return res.status === 201 ? res : res.data;
  },
  async (err) => {
    console.log('ERROR INTERCEPTOR');
    console.log(err);
    const originalConfig = err.config;
    console.log(originalConfig);
    if (originalConfig.url !== '/auth/signin' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log('ERROR INTERCEPTOR: 401');
      }
    }
    if (err.response.status === 403) {
      console.log('ACCESO PROHIBIDO');
      history.push('/notauthorized');
      history.go();
    }
    return Promise.reject(err);
  }
);
export default instance;
