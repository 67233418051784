// SORTING CASHBOXES BY DATE DESC
export const sorting = (cashboxes) =>
  cashboxes
    .sort((a, b) => new Date(a.deadTimeDate).getTime() - new Date(b.deadTimeDate).getTime())
    .reverse();

export const addLocaleConfig = {
  firstDayOfWeek: 1,
  showMonthAfterYear: true,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ],
  monthNamesShort: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ],
  today: 'Hoy',
  clear: 'Limpiar'
};
